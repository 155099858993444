import { ReactComponent as AINetwork } from 'app/icons/AINetwork.svg';
import { ReactComponent as Alert } from 'app/icons/Alert.svg';
import { ReactComponent as Arrow } from 'app/icons/Arrow.svg';
import { ReactComponent as ArrowDown } from 'app/icons/ArrowDown.svg';
import { ReactComponent as AtrialFibrillation } from 'app/icons/AtrialFibrillation.svg';
import { ReactComponent as Autoimmune } from 'app/icons/Autoimmune.svg';
import { ReactComponent as Bladder } from 'app/icons/Bladder.svg';
import { ReactComponent as Blood } from 'app/icons/Blood.svg';
import { ReactComponent as Brain } from 'app/icons/Brain.svg';
import { ReactComponent as Breast } from 'app/icons/Breast.svg';
import { ReactComponent as Brugada } from 'app/icons/Brugada.svg';
import { ReactComponent as Calendar } from 'app/icons/Calendar.svg';
import { ReactComponent as CancelCircle } from 'app/icons/CancelCircle.svg';
import { ReactComponent as Cardiomyopathy } from 'app/icons/Cardiomyopathy.svg';
import { ReactComponent as CardiomyopathyWarning } from 'app/icons/CardiomyopathyWarning.svg';
import { ReactComponent as Cervical } from 'app/icons/Cervical.svg';
import { ReactComponent as Check } from 'app/icons/Check.svg';
import { ReactComponent as ChevronDown } from 'app/icons/ChevronDown.svg';
import { ReactComponent as ChevronLeft } from 'app/icons/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'app/icons/ChevronRight.svg';
import { ReactComponent as ChevronUp } from 'app/icons/ChevronUp.svg';
import { ReactComponent as Clock } from 'app/icons/Clock.svg';
import { ReactComponent as Cogs } from 'app/icons/Cogs.svg';
import { ReactComponent as Coloteral } from 'app/icons/Coloteral.svg';
import { ReactComponent as Computer } from 'app/icons/Computer.svg';
import { ReactComponent as Contact } from 'app/icons/Contact.svg';
import { ReactComponent as Contracts } from 'app/icons/Contracts.svg';
import { ReactComponent as CoronaryArtery } from 'app/icons/CoronaryArtery.svg';
import { ReactComponent as DecreasedHDL } from 'app/icons/DecreasedHDL.svg';
import { ReactComponent as Diabetes1 } from 'app/icons/Diabetes-1.svg';
import { ReactComponent as Diabetes2 } from 'app/icons/Diabetes-2.svg';
import { ReactComponent as DiabetesGeneral } from 'app/icons/DiabetesGeneral.svg';
import { ReactComponent as DNA } from 'app/icons/DNA.svg';
import { ReactComponent as DocumentValidation } from 'app/icons/DocumentValidation.svg';
import { ReactComponent as Down } from 'app/icons/Down.svg';
import { ReactComponent as Duo } from 'app/icons/Duo.svg';
import { ReactComponent as ElevatedTriglycerides } from 'app/icons/ElevatedTriglycerides.svg';
import { ReactComponent as Endometrial } from 'app/icons/Endometrial.svg';
import { ReactComponent as FanconiAnemia } from 'app/icons/FanconiAnemia.svg';
import { ReactComponent as File } from 'app/icons/File.svg';
import { ReactComponent as FingerAccess } from 'app/icons/FingerAccess.svg';
import { ReactComponent as Gastric } from 'app/icons/Gastric.svg';
import { ReactComponent as Gastriointestinal } from 'app/icons/Gastriointestinal.svg';
import { ReactComponent as Glioblastoma } from 'app/icons/Glioblastoma.svg';
import { ReactComponent as HNSCC } from 'app/icons/HNSCC.svg';
import { ReactComponent as Hypertension } from 'app/icons/Hypertension.svg';
import { ReactComponent as Hyperthyroidism } from 'app/icons/Hyperthyroidism.svg';
import { ReactComponent as Hypothyroidism } from 'app/icons/Hypothyroidism.svg';
import { ReactComponent as Immune } from 'app/icons/Immune.svg';
import { ReactComponent as IncreasedLDL } from 'app/icons/IncreasedLDL.svg';
import { ReactComponent as Kidneys } from 'app/icons/Kidneys.svg';
import { ReactComponent as Lab } from 'app/icons/Lab.svg';
import { ReactComponent as Leukemia } from 'app/icons/Leukemia.svg';
import { ReactComponent as LinkIcon } from 'app/icons/Link.svg';
import { ReactComponent as LinkSquare } from 'app/icons/LinkSquare.svg';
import { ReactComponent as Liver } from 'app/icons/Liver.svg';
import { ReactComponent as Location } from 'app/icons/Location.svg';
import { ReactComponent as Lungs } from 'app/icons/Lungs.svg';
import { ReactComponent as Lymphoma } from 'app/icons/Lymphoma.svg';
import { ReactComponent as Mail } from 'app/icons/Mail.svg';
import { ReactComponent as Melanoma } from 'app/icons/Melanoma.svg';
import { ReactComponent as MenuCircle } from 'app/icons/MenuCircle.svg';
import { ReactComponent as MODY } from 'app/icons/MODY.svg';
import { ReactComponent as Neonatal } from 'app/icons/Neonatal.svg';
import { ReactComponent as Neuro1 } from 'app/icons/Neuro-1.svg';
import { ReactComponent as Neuro2 } from 'app/icons/Neuro-2.svg';
import { ReactComponent as Neuro3 } from 'app/icons/Neuro-3.svg';
import { ReactComponent as Neuro4 } from 'app/icons/Neuro-4.svg';
import { ReactComponent as Neuroblastoma } from 'app/icons/Neuroblastoma.svg';
import { ReactComponent as News } from 'app/icons/News.svg';
import { ReactComponent as Ovarian } from 'app/icons/Ovarian.svg';
import { ReactComponent as Package } from 'app/icons/Package.svg';
import { ReactComponent as Pancreatic } from 'app/icons/Pancreatic.svg';
import { ReactComponent as PDF } from 'app/icons/PDF.svg';
import { ReactComponent as PGXBrain } from 'app/icons/PGXBrain.svg';
import { ReactComponent as PGXDrugs } from 'app/icons/PGXDrugs.svg';
import { ReactComponent as PlayCircle } from 'app/icons/PlayCircle.svg';
import { ReactComponent as Proband } from 'app/icons/Proband.svg';
import { ReactComponent as Prostate } from 'app/icons/Prostate.svg';
import { ReactComponent as Prostate1 } from 'app/icons/Prostate-1.svg';
import { ReactComponent as Sarcoidosis } from 'app/icons/Sarcoidosis.svg';
import { ReactComponent as Search } from 'app/icons/Search.svg';
import { ReactComponent as ShoppingCartAdd } from 'app/icons/ShoppingCartAdd.svg';
import { ReactComponent as Thyroid } from 'app/icons/Thyroid.svg';
import { ReactComponent as ThyroidMalignancies } from 'app/icons/ThyroidMalignancies.svg';
import { ReactComponent as Trio } from 'app/icons/Trio.svg';
import { ReactComponent as Up } from 'app/icons/Up.svg';
import { ReactComponent as UserGroup } from 'app/icons/UserGroup.svg';
import { ReactComponent as UserStory } from 'app/icons/UserStory.svg';
import { Icon as IconType } from 'app/types/Icons';
import Icon from 'components/Icon/Icon';

import React from 'react';

export const getIconByType = (icon: IconType) => {
  switch (icon) {
    case 'ai-network':
      return <AINetwork />;
    case 'alert':
      return <Alert />;
    case 'arrow':
      return <Arrow />;
    case 'arrow-down':
      return <ArrowDown />;
    case 'atrial-fibrillation':
      return <AtrialFibrillation />;
    case 'autoimmune':
      return <Autoimmune />;
    case 'bladder':
      return <Bladder />;
    case 'blood':
      return <Blood />;
    case 'brain':
      return <Brain />;
    case 'breast':
      return <Breast />;
    case 'brugada':
      return <Brugada />;
    case 'calendar':
      return <Calendar />;
    case 'cancel-circle':
      return <CancelCircle />;
    case 'cardiomyopathy':
      return <Cardiomyopathy />;
    case 'cardiomyopathy-warning':
      return <CardiomyopathyWarning />;
    case 'cervical':
      return <Cervical />;
    case 'check':
      return <Check />;
    case 'chevron-down':
      return <ChevronDown />;
    case 'chevron-left':
      return <ChevronLeft />;
    case 'chevron-right':
      return <ChevronRight />;
    case 'chevron-up':
      return <ChevronUp />;
    case 'clock':
      return <Clock />;
    case 'cogs':
      return <Cogs />;
    case 'coloteral':
      return <Coloteral />;
    case 'computer':
      return <Computer />;
    case 'contact':
      return <Contact />;
    case 'contracts':
      return <Contracts />;
    case 'coronary-artery':
      return <CoronaryArtery />;
    case 'decreased-hdl':
      return <DecreasedHDL />;
    case 'diabetes-1':
      return <Diabetes1 />;
    case 'diabetes-2':
      return <Diabetes2 />;
    case 'diabetes-general':
      return <DiabetesGeneral />;
    case 'dna':
      return <DNA />;
    case 'document-validation':
      return <DocumentValidation />;
    case 'down':
      return <Down />;
    case 'duo':
      return <Duo />;
    case 'elevated-triglycerides':
      return <ElevatedTriglycerides />;
    case 'endometrial':
      return <Endometrial />;
    case 'fanconi-anemia':
      return <FanconiAnemia />;
    case 'file':
      return <File />;
    case 'finger-access':
      return <FingerAccess />;
    case 'gastric':
      return <Gastric />;
    case 'gastriointestinal':
      return <Gastriointestinal />;
    case 'glioblastoma':
      return <Glioblastoma />;
    case 'hnscc':
      return <HNSCC />;
    case 'hypertension':
      return <Hypertension />;
    case 'hyperthyroidism':
      return <Hyperthyroidism />;
    case 'hypothyroidism':
      return <Hypothyroidism />;
    case 'immune':
      return <Immune />;
    case 'increased-ldl':
      return <IncreasedLDL />;
    case 'kidneys':
      return <Kidneys />;
    case 'lab':
      return <Lab />;
    case 'leukemia':
      return <Leukemia />;
    case 'link':
      return <LinkIcon />;
    case 'link-square':
      return <LinkSquare />;
    case 'liver':
      return <Liver />;
    case 'location':
      return <Location />;
    case 'lungs':
      return <Lungs />;
    case 'lymphoma':
      return <Lymphoma />;
    case 'mail':
      return <Mail />;
    case 'melanoma':
      return <Melanoma />;
    case 'menu-circle':
      return <MenuCircle />;
    case 'mody':
      return <MODY />;
    case 'neonatal':
      return <Neonatal />;
    case 'neuro-1':
      return <Neuro1 />;
    case 'neuro-2':
      return <Neuro2 />;
    case 'neuro-3':
      return <Neuro3 />;
    case 'neuro-4':
      return <Neuro4 />;
    case 'neuroblastoma':
      return <Neuroblastoma />;
    case 'news':
      return <News />;
    case 'ovarian':
      return <Ovarian />;
    case 'package':
      return <Package />;
    case 'pancreatic':
      return <Pancreatic />;
    case 'pdf':
      return <PDF />;
    case 'pgx-brain':
      return <PGXBrain />;
    case 'pgx-drugs':
      return <PGXDrugs />;
    case 'play-circle':
      return <PlayCircle />;
    case 'proband':
      return <Proband />;
    case 'prostate':
      return <Prostate />;
    case 'prostate-1':
      return <Prostate1 />;
    case 'sarcoidosis':
      return <Sarcoidosis />;
    case 'search':
      return <Search />;
    case 'shopping-cart-add':
      return <ShoppingCartAdd />;
    case 'thyroid':
      return <Thyroid />;
    case 'thyroid-malignancies':
      return <ThyroidMalignancies />;
    case 'trio':
      return <Trio />;
    case 'up':
      return <Up />;
    case 'user-group':
      return <UserGroup />;
    case 'user-story':
      return <UserStory />;
    default:
      return <span />;
  }
};

export const renderIcon = (icon: IconType, className?: string) => {
  const i = getIconByType(icon);

  return <Icon key={icon} icon={i} className={className} />;
};
